$( document ).ready(function() {

    // $('#video-btn').on('click', function(e){
    //
    //     if($(window).width() <= 600){
    //
    //         $('[data-fancybox]').fancybox();
    //     }else{
    //         $.fancybox.destroy();
    //         e.preventDefault();
    //         var icon  = $(this).find('.play');
    //
    //         if(icon.hasClass('play')){
    //             document.getElementById("myVideo").pause();
    //             icon.toggleClass('play play');
    //             $('#top .container-fluid .row .title').fadeTo(1000, 1);
    //
    //
    //         }else{
    //             document.getElementById("myVideo").play();
    //             $(this).fadeOut(function(){
    //                 icon.toggleClass('play play');
    //                 $(this).removeClass('show')
    //             });
    //
    //             $('#top .container-fluid .row .title').fadeTo(2000, 0);
    //         }
    //     }
    // })
    //
    // $( "body" ).mousemove(function( event ) {
    //     var icon  = $(this).find('#video-container #video-btn .fas');
    //
    //     if(icon.hasClass('play')){
    //         $('#video-btn').fadeIn();
    //     };
    //
    // });

});
